import React, { useState, useEffect, useRef } from "react";
import {
  getCodes,
  updateIbCode,
  generateCode,
} from "../../../redux/actions/adminActions";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  Spinner,
  Button,
} from "react-bootstrap";
import moment from "moment";
import { CLIENT_ADMIN_URL, WEBSITE_URL } from "../../../config/config";
import { NotificationManager } from "react-notifications";

const IbCodes = () => {
  const [loading, setLoading] = useState(true);
  const [ibCode, setIbCode] = useState(null);
  const [ibCodes, setIbCodes] = useState(null);
  const [showGenerateCodeModal, setShowGenerateCodeModal] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const codeName = useRef("");

  useEffect(() => {
    triggerLoadIbCodes();
  }, []);

  const triggerLoadIbCodes = () => {
    getCodes()
      .then(res => {
        setIbCode(res.data.ibCode.code);
        setIbCodes(res.data.codes);
        setLoading(false);
      })
      .catch(err => console.error(err));
  };

  const handleUpdateIbCode = ibData => {
    updateIbCode(ibData)
      .then(res => {
        if (res.data.success) {
          createNotification("successEdit");
        } else {
          createNotification("error");
        }
      })
      .catch(err => console.error(err));
  };

  const handleGenerateCode = () => {
    let name =
      codeName.current.value.trim().length === 0
        ? "NAME"
        : codeName.current.value;

    generateCode({
      name,
    })
      .then(res => {
        if (res.data.success) {
          createNotification("successGenerate");
          setBtnDisabled(false);
          setShowGenerateCodeModal(false);
          setLoading(true);

          triggerLoadIbCodes();
        } else {
          setBtnDisabled(false);
          setShowGenerateCodeModal(false);
          createNotification("generatedTooMany");
        }
      })
      .catch(err => console.error(err));
  };

  const handleModalShow = () => {
    setShowGenerateCodeModal(true);
  };

  const handleModalClose = () => {
    setShowGenerateCodeModal(false);
  };

  const columns = [
    {
      dataField: "name",
      text: "NAME",
      sort: true,
      classes: "breakWord",
      editable: true,
    },
    // {
    //   dataField: "code",
    //   text: "CODE",
    //   sort: false,
    //   classes: "breakWord",
    //   editable: false,
    // },
    {
      dataField: "link",
      text: "LINK",
      sort: true,
      classes: "breakWord",
      editable: false,
    },
    {
      dataField: "timeCreated",
      text: "ADDED",
      sort: true,
      editable: false,
    },
  ];

  const defaultSorted = [
    {
      dataField: "timeApproved",
      order: "asc",
    },
  ];

  let data = [];
  if (!!ibCodes) {
    ibCodes.forEach((keyName, keyIndex) => {
      // Some data formatting before displaying
      ibCodes[keyIndex].timeCreated = moment(ibCodes[keyIndex].timestamp * 1000)
        .format("YYYY/MM/DD HH:mm:ss")
        .toString();

      ibCodes[
        keyIndex
      ].link = `${WEBSITE_URL}/?access=${ibCodes[keyIndex].code}`;

      data.push(ibCodes[keyIndex]);
    });
  }

  const createNotification = type => {
    switch (type) {
      case "successEdit":
        NotificationManager.success(
          "You have successfully updated the code name.",
          "IB Code Update",
          5000
        );
        break;
      case "successGenerate":
        NotificationManager.success(
          "You have successfully generated the code.",
          "IB Code Generator",
          5000
        );
        break;
      case "generatedTooMany":
        NotificationManager.error(
          "You can only generate 10 codes.",
          "Error",
          5000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong!", "Error", 5000);
        break;
      default:
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="pt-3 px-3">
              IB Codes {loading ? <Spinner animation="border" /> : null}
              <Button
                type="button"
                variant="secondary"
                className="darkBlueButton float-right ml-1"
                style={{ width: "auto", fontWeight: "normal", marginTop: 0 }}
                onClick={() => handleModalShow("showGenerateCodesModal")}
              >
                Generate code
              </Button>
            </h1>
          </Col>
        </Row>
        <Row>
          <Col className="px-5 text-right">
            Main code:{" "}
            <strong>{`${CLIENT_ADMIN_URL}/register/${ibCode}`}</strong>
          </Col>
        </Row>
        <Row>
          <Col>
            <BootstrapTable
              bootstrap4
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              hover={true}
              headerClasses="tableHeaderClass"
              cellEdit={cellEditFactory({
                mode: "dbclick",
                blurToSave: true,
                afterSaveCell: (oldValue, newValue, row, column) => {
                  if (oldValue.toString() !== newValue.toString()) {
                    handleUpdateIbCode({
                      ibId: row._id,
                      [column.dataField]: row[column.dataField],
                    });
                  }
                },
              })}
            />
          </Col>
        </Row>
      </Container>

      <Modal show={showGenerateCodeModal} onHide={handleModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Generate Codes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Code Name</Form.Label>
            <Form.Control type="text" className="roundedBox" ref={codeName} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-between">
          <Button
            variant="secondary"
            className="greyButton"
            onClick={() => handleModalClose()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="darkBlueButton"
            style={{
              fontWeight: "normal",
            }}
            disabled={btnDisabled}
            onClick={handleGenerateCode}
          >
            {btnDisabled ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Submit
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IbCodes;
