import axios from "axios";
import store from "../../redux/store";
import { GET_ERRORS, GET_SUCCESS, SET_MENU_COUNTERS } from "./types";
import { API_URL } from "../../config/config";

// Get all users
export const getUsers = (agentCode, status, limit) =>
  axios.get(
    `${API_URL}/api/partners/users/list/${agentCode}/${status}/${limit}`
  );

// Get all accounts by agent code
export const getUserTradingAccounts = (type, status) =>
  axios.get(`${API_URL}/api/partners/accounts/getAccounts/${type}/${status}`);

// Get all codes
export const getCodes = () =>
  axios.get(`${API_URL}/api/partners/users/getCodes`);

// Update IB Code
export const updateIbCode = ibCodeId =>
  axios.post(API_URL + "/api/partners/users/updateIbCode", ibCodeId);

// Update IB Code
export const generateCode = data =>
  axios.post(API_URL + "/api/partners/users/generateCode", data);

// Get new requests count
export const getNewRequestsCount = agentCode =>
  axios.get(API_URL + "/api/partners/users/getNewRequestsCount/" + agentCode);

// Get Email Notifications
export const getEmailNotifications = () =>
  axios.get(API_URL + "/api/partners/settings/getEmailNotifications/");

// Update Email Notifications
export const updateEmailNotifications = settings => dispatch => {
  axios
    .post(API_URL + "/api/partners/settings/updateEmailNotifications", settings)
    .then(res =>
      dispatch({
        type: GET_SUCCESS,
        payload: res.data,
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload:
          err.response !== undefined
            ? err.response.data
            : { server: "Server error." },
      })
    );
};

// Set new clients
export const setMenuCounters = counters => ({
  type: SET_MENU_COUNTERS,
  payload: counters,
});

// Clear success/error props
export const clearSuccessErrorProps = () => {
  store.dispatch({
    type: GET_SUCCESS,
    payload: {},
  });
  store.dispatch({
    type: GET_ERRORS,
    payload: {},
  });
};
