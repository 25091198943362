import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { countryList } from "../../../../config/constants";
import { getUsers } from "../../../../redux/actions/adminActions";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import moment from "moment";
import IconApproved from "../../../../assets/images/icon-approved.svg";
import IconPending from "../../../../assets/images/icon-pending.svg";
import IconRejected from "../../../../assets/images/icon-rejected.svg";
import IconNewClient from "../../../../assets/images/icon-new-client.svg";

const UserList = props => {
  const [state, setState] = useState({
    users: {},
    loadingUsers: true,
    errors: {},
  });

  const statusTitles = {
    all: "All Accounts",
    new: "New Clients",
    approved: "Approved Accounts",
    pending: "Pending Accounts",
    rejected: "Rejected Accounts",
  };

  useEffect(() => {
    triggerLoadUsers();

    const userLoader = setInterval(triggerLoadUsers, 30000);

    // Clean up setInterval
    return () => {
      clearInterval(userLoader);
    };
  }, [props.match.params.status]);

  const triggerLoadUsers = () => {
    setState(prevState => {
      return {
        ...prevState,
        loadingUsers: true,
      };
    });
    getUsers(props.auth.user.agentCode, props.match.params.status, 0)
      .then(res => {
        setState(prevState => {
          return {
            ...prevState,
            users: res.data.clients,
            loadingUsers: false,
          };
        });
      })
      .catch(err => console.log(err));
  };

  const { users, loadingUsers } = state;

  const selectOptionsCompletedApp = {
    0: "All",
    1: "Incomplete",
    2: "Completed",
  };

  const selectOptionsKYCApproved = {
    0: "All",
    1: "Non-approved",
    2: "Approved",
  };

  const customFilterCompletedApp = (filterVal, data) => {
    const search = parseInt(filterVal);

    if (search > 0) {
      return data.filter(
        user =>
          (user.registered === true && search === 2) ||
          (user.registered === false && search === 1)
      );
    }
    return data;
  };

  const customFilterKYCApproved = (filterVal, data) => {
    let search = parseInt(filterVal);

    if (search > 0) {
      search = search === 2;
      return data.filter(user => user.eKYCApproved === search);
    }
    return data;
  };

  const columns = [
    {
      dataField: "userStatus",
      text: "STATUS",
      // sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "timestamp",
      text: "TIME OF REGISTRATION",
      sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "accountType",
      text: "APPLICATION TYPE",
      // sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
    {
      dataField: "name",
      text: "FIRST NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "surname",
      text: "LAST NAME",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "email",
      text: "EMAIL",
      sort: true,
      classes: "breakWord",
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "userCountry",
      text: "COUNTRY OF RESID.",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "agentCode",
      text: "AGENT CODE",
      sort: true,
      filter: textFilter({ placeholder: "Search..." }),
    },
    {
      dataField: "completedApplication",
      text: "COMPLETE APPLICATION",
      // sort: true,
      filter: selectFilter({
        options: selectOptionsCompletedApp,
        onFilter: customFilterCompletedApp,
      }),
    },
    {
      dataField: "eKYCApprovedIcon",
      text: "eKYC APPROVED",
      // sort: true,
      filter: selectFilter({
        options: selectOptionsKYCApproved,
        onFilter: customFilterKYCApproved,
      }),
    },
    {
      dataField: "reason",
      text: "REASON",
      // sort: true,
      // filter: textFilter({placeholder: 'Search...'})
    },
  ];

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "asc",
    },
  ];

  let data = [];
  if (Object.keys(users).length > 0) {
    for (let i = 0; i < users.length; i++) {
      // Some data formatting before displaying
      let userStatusClass;
      let userStatusIcon;

      let statusText = users[i].status;
      switch (users[i].status) {
        case "new":
          userStatusClass = "statusFlagNewClient";
          userStatusIcon = IconNewClient;
          statusText = "New Client";
          break;
        case "pending":
          userStatusClass = "statusFlagPending";
          userStatusIcon = IconPending;
          break;
        case "rejected":
          userStatusClass = "statusFlagRejected";
          userStatusIcon = IconRejected;
          break;
        default:
          userStatusClass = "statusFlagApproved";
          userStatusIcon = IconApproved;
      }

      users[i].userStatus = (
        <span className={["statusFlag", userStatusClass].join(" ")}>
          <img src={userStatusIcon} alt="" /> {statusText}
        </span>
      );

      if (Number.isInteger(users[i].timestamp)) {
        users[i].timestamp = moment(users[i].timestamp * 1000)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
      }

      users[i].userCountry = countryList[users[i].country];

      users[i].eKYCApprovedIcon =
        users[i].eKYCApproved === true ? (
          <img src={IconApproved} alt="" />
        ) : users[i].eKYCFlagged ? (
          <span className="text-danger">FLAGGED</span>
        ) : (
          <img src={IconRejected} alt="" />
        );

      users[i].completedApplication =
        users[i].registered === true ? (
          <img src={IconApproved} alt="" />
        ) : (
          <img src={IconRejected} alt="" />
        );

      data.push(users[i]);
    }
  }

  const rowStyle = (row, rowIndex) => {
    let style = {};

    if (row.status === "approved" && !row.firstAccountCreated) {
      style = { color: "#f00" };
    }

    return style;
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1 className="pt-3 px-3">
              {statusTitles[props.match.params.status]}{" "}
              {loadingUsers ? <Spinner animation="border" /> : null}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <BootstrapTable
              bootstrap4
              bordered={false}
              keyField="_id"
              data={data}
              columns={columns}
              defaultSorted={defaultSorted}
              hover={true}
              filter={filterFactory()}
              rowStyle={rowStyle}
              headerClasses="tableHeaderClass"
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

UserList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps)(UserList);
