import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import classes from "../../Auth/Login/Login.module.css";
import { FiAlertTriangle } from "react-icons/fi";
import {
  clearSuccessErrorProps,
  getEmailNotifications,
  updateEmailNotifications,
} from "../../../redux/actions/adminActions";
import { NotificationManager } from "react-notifications";

const AdminSettings = props => {
  const [emailNotifications, setEmailNotifications] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    getEmailNotifications()
      .then(res => {
        setEmailNotifications(res.data[0]);
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (Object.keys(props.success).length > 0) {
      createNotification("success");

      setDisableBtn(false);
      clearSuccessErrorProps();
    }

    if (Object.keys(props.errors).length > 0) {
      createNotification("error");

      setErrors(props.errors);
      setDisableBtn(false);
      clearSuccessErrorProps();
    }
  }, [props]);

  const createNotification = type => {
    switch (type) {
      case "success":
        NotificationManager.success(
          "You have successfully updated email settings.",
          "Email Notifications",
          5000
        );
        break;
      case "error":
        NotificationManager.error("Something went wrong!", "Error", 5000);
        break;
      default:
    }
  };

  const handleSubmitEmailNotifications = event => {
    event.preventDefault();
    event.stopPropagation();

    const settings = {
      partnerUserRegistered: document
        .getElementById("partnerUserRegistered")
        .value.trim(),
      partnerUserApproved: document
        .getElementById("partnerUserApproved")
        .value.trim(),
      partnerUserKYCApproved: document
        .getElementById("partnerUserKYCApproved")
        .value.trim(),
      partnerUserDeposited: document
        .getElementById("partnerUserDeposited")
        .value.trim(),
      // partnerUserRequestedWireTransfer: document
      //   .getElementById("partnerUserRequestedWireTransfer")
      //   .value.trim(),
      partnerUserWithdrawalRequest: document
        .getElementById("partnerUserWithdrawalRequest")
        .value.trim(),
    };

    // console.log(emailNotifications);
    setDisableBtn(true);
    props.updateEmailNotifications(settings);
  };

  return (
    <Container>
      <Row className="justify-content-lg-center">
        <Col>
          <h1 className="pt-3 px-3">Email Notifications</h1>
        </Col>
      </Row>

      <Row className="px-3 pt-4">
        <Col xs="12" md="7">
          <Form onSubmit={e => handleSubmitEmailNotifications(e)}>
            <Form.Group as={Row}>
              <Form.Label
                className={[classes.label, "pt-2"].join(" ")}
                as={Col}
                xs="12"
                md="6"
              >
                User Registered
              </Form.Label>
              <Col xs="12" md="6">
                <Form.Control
                  type="text"
                  name="partnerUserRegistered"
                  id="partnerUserRegistered"
                  defaultValue={
                    emailNotifications &&
                    emailNotifications.partnerUserRegistered
                      ? emailNotifications.partnerUserRegistered
                      : ""
                  }
                  required
                />
              </Col>

              <Form.Text style={{ color: "red" }}>
                {errors.partnerUserRegistered}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label
                className={[classes.label, "pt-2"].join(" ")}
                as={Col}
                xs="12"
                md="6"
              >
                User Approved
              </Form.Label>
              <Col xs="12" md="6">
                <Form.Control
                  type="text"
                  name="partnerUserApproved"
                  id="partnerUserApproved"
                  defaultValue={
                    emailNotifications && emailNotifications.partnerUserApproved
                      ? emailNotifications.partnerUserApproved
                      : ""
                  }
                  required
                />
              </Col>

              <Form.Text style={{ color: "red" }}>
                {errors.partnerUserApproved}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label
                className={[classes.label, "pt-2"].join(" ")}
                as={Col}
                xs="12"
                md="6"
              >
                User KYC Approved
              </Form.Label>
              <Col xs="12" md="6">
                <Form.Control
                  type="text"
                  name="partnerUserKYCApproved"
                  id="partnerUserKYCApproved"
                  defaultValue={
                    emailNotifications &&
                    emailNotifications.partnerUserKYCApproved
                      ? emailNotifications.partnerUserKYCApproved
                      : ""
                  }
                  required
                />
              </Col>

              <Form.Text style={{ color: "red" }}>
                {errors.partnerUserKYCApproved}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label
                className={[classes.label, "pt-2"].join(" ")}
                as={Col}
                xs="12"
                md="6"
              >
                User Deposited
              </Form.Label>
              <Col xs="12" md="6">
                <Form.Control
                  type="text"
                  name="partnerUserDeposited"
                  id="partnerUserDeposited"
                  defaultValue={
                    emailNotifications &&
                    emailNotifications.partnerUserDeposited
                      ? emailNotifications.partnerUserDeposited
                      : ""
                  }
                  required
                />
              </Col>

              <Form.Text style={{ color: "red" }}>
                {errors.partnerUserDeposited}
              </Form.Text>
            </Form.Group>

            {/*<Form.Group as={Row}>*/}
            {/*  <Form.Label*/}
            {/*    className={[classes.label, "pt-2"].join(" ")}*/}
            {/*    as={Col}*/}
            {/*    xs="12"*/}
            {/*    md="6"*/}
            {/*  >*/}
            {/*    User Requested Wire Transfer*/}
            {/*  </Form.Label>*/}
            {/*  <Col xs="12" md="6">*/}
            {/*    <Form.Control*/}
            {/*      type="text"*/}
            {/*      name="partnerUserRequestedWireTransfer"*/}
            {/*      id="partnerUserRequestedWireTransfer"*/}
            {/*      defaultValue={*/}
            {/*        emailNotifications &&*/}
            {/*        emailNotifications.partnerUserRequestedWireTransfer*/}
            {/*          ? emailNotifications.partnerUserRequestedWireTransfer*/}
            {/*          : ""*/}
            {/*      }*/}
            {/*      required*/}
            {/*    />*/}
            {/*  </Col>*/}

            {/*  <Form.Text style={{ color: "red" }}>*/}
            {/*    {errors.partnerUserRequestedWireTransfer}*/}
            {/*  </Form.Text>*/}
            {/*</Form.Group>*/}

            <Form.Group as={Row}>
              <Form.Label
                className={[classes.label, "pt-2"].join(" ")}
                as={Col}
                xs="12"
                md="6"
              >
                User Withdrawal Request
              </Form.Label>
              <Col xs="12" md="6">
                <Form.Control
                  type="text"
                  name="partnerUserWithdrawalRequest"
                  id="partnerUserWithdrawalRequest"
                  defaultValue={
                    emailNotifications &&
                    emailNotifications.partnerUserWithdrawalRequest
                      ? emailNotifications.partnerUserWithdrawalRequest
                      : ""
                  }
                  required
                />
              </Col>

              <Form.Text style={{ color: "red" }}>
                {errors.partnerUserWithdrawalRequest}
              </Form.Text>
            </Form.Group>

            <Form.Text className="text-left pb-4">
              {errors.server ? (
                <FiAlertTriangle className={classes.warningIcon} />
              ) : null}
              {errors.server}
              {errors.password}
            </Form.Text>

            <Button
              type="submit"
              className="blueButton mt-0 mb-4 float-left"
              onClick={handleSubmitEmailNotifications}
              disabled={disableBtn}
            >
              {disableBtn ? (
                <>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  UPDATE
                </>
              ) : (
                "UPDATE"
              )}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

AdminSettings.propTypes = {
  auth: PropTypes.object.isRequired,
  updateEmailNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  success: state.success,
  errors: state.errors,
});

export default connect(mapStateToProps, { updateEmailNotifications })(
  AdminSettings
);
