import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../assets/images/iQuoto-Global-Logo-White.svg";
import SidebarCounter from "../SidebarCounter/SidebarCounter";

const SidebarAdmin = props => {
  const [activeItem, setActiveItem] = useState("");

  useEffect(() => {
    setActiveItem(window.location.pathname);
  }, []);

  const handleMenuItemClicked = destination => {
    setActiveItem(destination);
  };

  return (
    <>
      <object
        data={logo}
        width="172"
        height="65"
        className="sidebarLogo sidebarLogoAdmin"
        aria-label="iQuoto.com"
      />

      <div className="sidebarUser">
        <div className="menuItemTitle">CLIENT MANAGEMENT</div>
        <Button
          as={Link}
          to="/users/all"
          onClick={() => handleMenuItemClicked("/users/all")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/all") ? "active" : null,
          ]}
        >
          All Accounts
        </Button>
        <Button
          as={Link}
          to="/users/new"
          onClick={() => handleMenuItemClicked("/users/new")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/new") ? "active" : null,
          ]}
        >
          New Clients
          {props.global.newRequests.newClients > 0 ? (
            <SidebarCounter number={props.global.newRequests.newClients} />
          ) : null}
        </Button>
        <Button
          as={Link}
          to="/users/approved"
          onClick={() => handleMenuItemClicked("/users/approved")}
          className={[
            "menuItem py-2",
            activeItem.includes("/users/approved") ? "active" : null,
          ]}
        >
          Approved Accounts
        </Button>

        <div className="menuItemTitle">TRADING ACCOUNTS</div>
        <Button
          as={Link}
          to="/trading-accounts"
          onClick={() => handleMenuItemClicked("/trading-accounts")}
          className={[
            "menuItem py-2",
            activeItem === "/trading-accounts" ? "active" : null,
          ]}
        >
          Trading Accounts
        </Button>
        <Button
          as={Link}
          to="/ib-links"
          onClick={() => handleMenuItemClicked("/ib-links")}
          className={[
            "menuItem py-2",
            activeItem === "/ib-links" ? "active" : null,
          ]}
        >
          IB Links
        </Button>

        <div className="menuItemTitle">SETTINGS</div>
        <Button
          as={Link}
          to="/settings/email-notifications"
          onClick={() => handleMenuItemClicked("/settings/email-notifications")}
          className={[
            "menuItem py-2",
            activeItem.includes("/settings/email-notifications")
              ? "active"
              : null,
          ]}
        >
          Email Notifications
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  auth: state.auth,
  global: state.global,
});

export default connect(mapStateToProps)(SidebarAdmin);
